.chat-section,
.ce-chat-container,
.ce-settings {
  background-color: var(--background-color);
}
.ce-chat-engine{
  background-color: transparent !important;
}
.ce-settings {
  background-color: var(--background-color) !important;
  .div {
    border-color: var(--background-color) !important;
  }
}
.ce-settings::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.ce-settings::-webkit-scrollbar-track {
  background: transparent;
}

.ce-settings::-webkit-scrollbar-thumb {
  cursor: pointer;

  border-radius: 30px;
  background: var(--Secondary-Grey-50, #dfdfe1);
}
.ce-settings::-webkit-scrollbar-thumb:hover {
  border-radius: 30px;
  background: #8f8f8f;
  cursor: pointer;
}
.ce-input {
  // background-color: var(--background-color);
  // color: white;
  display: flex;
  height: 50px !important;
  padding: 16px 28px !important;
  align-items: flex-start !important;
  gap: 8px !important;
  flex-shrink: 0 !important;
  align-self: stretch !important;
  border-radius: 15px !important;
  border: 1px solid #ececed !important;
  background: #fff !important;
  color: #14171f;
  font-size: 16px !important;
  margin-top: 10px !important;
}
.ce-section-title-container {
  background-color: var(--background-color) !important;
}
.ce-photo-section {
  background-color: var(--background-color) !important;
}
#ce-options-drop-down {
  background-color: var(--background-color) !important;
}

.ce-chat-feed {
  background-color: var(--background-color) !important;
}
.ce-chat-feed-container {
  background-color: var(--background-color) !important;
}
.ce-chat-feed-container::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.ce-chat-feed-container::-webkit-scrollbar-track {
  background: transparent;
}

.ce-chat-feed-container::-webkit-scrollbar-thumb {
  cursor: pointer;

  border-radius: 30px;
  background: var(--Secondary-Grey-50, #dfdfe1);
}
.ce-chat-feed-container::-webkit-scrollbar-thumb:hover {
  border-radius: 30px;
  background: #8f8f8f;
  cursor: pointer;
}
.ce-chat-title,
.ce-social-message-form-container,
.ce-chat-form-container,
.ce-chats-container,
.ce-chat-list {
  background-color: var(--background-color) !important;
}
.ce-chat-list::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.ce-chat-list::-webkit-scrollbar-track {
  background: transparent;
}

.ce-chat-list::-webkit-scrollbar-thumb {
  cursor: pointer;

  border-radius: 30px;
  background: var(--Secondary-Grey-50, #dfdfe1);
}
.ce-chat-list::-webkit-scrollbar-thumb:hover {
  border-radius: 30px;
  background: #8f8f8f;
  cursor: pointer;
}
.ce-chat-card {
  background-color: var(--box-color) !important;
  margin: 0px 14px 14px 14px;
  // border: none !important;
  border-radius: 11px !important;
  color: white !important;
  border: 1px solid #3e92cc !important;
}
.ce-autocomplete-option div div{
  color: #14171f !important;
}
.ce-primary-button {
  background-color: #3e92cc !important;
  color: white !important;
  border-radius: 11px !important;
  height: 44px !important;
  font-size: 17px !important;
  border: none !important;
}
.ce-avatar {
  border-radius: 13px !important;
  margin-bottom: 10px !important;
}
.ce-message-date-text {
  color: white !important;
}
.send-icon {
  transform: rotate(0deg) !important;
  margin-left: 0 !important;
  width: 25px !important;
}