@keyframes fadeInAnimationLoader {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
.loader {
  /* Center the loader */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fix the loader to the viewport */
  top: 0;
  left: 0;
  background: #261a33; /* Semi-transparent background */
  z-index: 9999; /* Ensure the loader appears on top of other content */
  img {
    max-width: 350px;
    max-height: 350px;
    animation: fadeInAnimationLoader ease 3s infinite;
    // animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}
